.firebase-emulator-warning {
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #99a0fa;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.rounded-image {
  border-radius: 0.5em;
}

#hj-survey-toggle-1 {
  display: none;
  --hjFeedbackAccentColor: #3d4bf5 !important;
}
